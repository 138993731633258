import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Heading = makeShortcode("Heading");
const Button = makeShortcode("Button");
const ButtonGroup = makeShortcode("ButtonGroup");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section size='medium' style={{
      backgroundColor: '#f8fff8',
      backgroundImage: 'url(https://images.unsplash.com/photo-1438707207584-d9fcda4a60e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)',
      backgroundSize: 'cover',
      backgroundPosition: '0 20%'
    }} mdxType="Section">
  <Container className='presentation' mdxType="Container">
    <Columns mdxType="Columns">
      <Column size={6} mdxType="Column">
        <Box mdxType="Box">
          <br />
          <Heading renderAs='h1' size={3} mdxType="Heading">
            Conditions Générales d'Utilisation
          </Heading>
          <Button renderAs='a' color='primary' inverted href='/legal/cgu' mdxType="Button">
            Voir les conditions d'utilisation
          </Button>
          <br />
        </Box>
        <Box mdxType="Box">
          <br />
          <Heading renderAs='h1' size={3} mdxType="Heading">
            Mentions Légales
          </Heading>
          <Button renderAs='a' color='primary' inverted href='/legal/mentions-legales' mdxType="Button">
            Voir les mentions légales
          </Button>
          <br />
        </Box>
      </Column>
      <Column size={6} mdxType="Column">
        <Box mdxType="Box">
          <br />
          <Heading renderAs='h1' size={3} mdxType="Heading">
            Conditions Générales de Vente
          </Heading>
          <ButtonGroup mdxType="ButtonGroup">
            <Button renderAs='a' color='primary' inverted href='/legal/cgv-acheteurs' mdxType="Button">
              Voir les conditions de vente Acheteurs
            </Button>
            <Button renderAs='a' color='primary' inverted href='/legal/cgv-fournisseurs' mdxType="Button">
              Voir les conditions de vente Fournisseurs
            </Button>
          </ButtonGroup>
          <br />
        </Box>
      </Column>
    </Columns>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      